import our_expertise_it from "./expertise";

const common_it = {
  letstalk: "parliamo",
  english: "inglese",
  spanish: "spagnolo",
  italian: "italiano",
  portuguese: "portoghese",
  we_are_a_global_crew: "siamo un team globale",
  of_swift_thinkers_and_makers: "DI VELOCI PENSATORI E",
  designing_possible_futures: "CREATORI CHE PROGETTANO FUTURI POSSIBILI",
  our_approach: "IL NOSTRO APPROCCIO",
  our_approach_description:
    "Sulla base della nostra esperienza e visione del futuro, innoviamo fino ad ottenere risposte e progettiamo soluzioni memorabili per migliorare le performance aziendali e le esperienze delle persone.",
  in_the_last_9_years: "NEGLI ULTIMI 9 ANNI, ABBIAMO",
  design_leadership_to_more_than_300: "LIDERATO PIÙ DI 300",
  projects_for_60_clients: "PROGETTI PER 60 CLIENTI, IN 13 PAESI",
  our_expertise: {
    title: "LA NOSTRA COMPETENZA",
    tab: {
      health_care: our_expertise_it[0].name,
      finance: our_expertise_it[1].name,
      government_ngo: our_expertise_it[2].name,
      telecom_utilities: our_expertise_it[3].name,
      services: our_expertise_it[4].name,
    },
    navigation: {
      health_care: our_expertise_it[0].name,
      finance: our_expertise_it[1].name,
      government_ngo: our_expertise_it[2].name,
      telecom_utilities: our_expertise_it[3].name,
      services: our_expertise_it[4].name,
    },
    description: {
      health_care: our_expertise_it[0].description,
      finance: our_expertise_it[1].description,
      government_ngo: our_expertise_it[2].description,
      telecom_utilities: our_expertise_it[3].description,
      services: our_expertise_it[4].description,
    },
    link: {
      health_care: our_expertise_it[0].link.name,
      finance: our_expertise_it[1].link.name,
      government_ngo: our_expertise_it[2].link.name,
      telecom_utilities: our_expertise_it[3].link.name,
      services: our_expertise_it[4].link.name,
    },
  },
  our_stories: {
    title: "le nostre storie",
    items: {
      new_digital_government: "NUOVO GOVERNO DIGITALE",
      in_person_banking_in_a_digital_world:
        "LA FILIALE DELLA BANCA IN UN MONDO DIGITALE",
      payment_experience: "ESPERIENZA DI PAGAMENTO",
      a_more_inclusive_education: "UN'EDUCAZIONE PIÙ INCLUSIVA",
      digital_banking_for_all: "BANCA DIGITALE PER TUTTI",
      digital_journey_to_a_real_journey: "VIAGGIO DIGITALE VERSO UN VIAGGIO REALE",
      telecom_globally_act_local: "TELECOM GLOBALE, AGIRE LOCALE",
      healthcare_with_more_care: "SANITÀ CON PIÙ CURE",
    },
  },
  clients: "clienti", 
  our_partners: "our partners",
  get_in_touch: "CONTATTACI",
  all_rights_reserved: "Tutti i diritti riservati.",
  privacy_and_data_policy: "Informativa sulla privacy e sui dati.",
};

export default common_it;
