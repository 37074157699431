import './Footer.scss';
import { useTranslation } from 'react-i18next';
import IconArrowRight from '../../assets/img/icon-arrow-right.svg';
import LogoFooter from '../../assets/img/Logo-footer.svg';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer id="footer" className="footer">
      {t("common:")}

      <div
        data-aos="fade-right"
        data-aos-duration={600}
        className="footer-header">
        <h3>{t("common:get_in_touch")}</h3>
        <img src={IconArrowRight} alt="arrow-right" />
      </div>

      <div
        data-aos="fade-up"
        data-aos-duration={1000}>
        <div className="footer-nav-wrap">
          <ul className="footer-nav">
            <li className="footer-item">
              <h3>Hola!</h3>
              <a href='mailto:hola@medible.cl'>hola@medible.cl</a>
            </li>
            <li className="footer-item">
              <h3>hi!</h3>
              <a href='mailto:hi@medible.uk'>hi@medible.uk</a>
            </li>
          </ul>
          <ul className="footer-nav">
            <li className="footer-item">
              <h3>Ciao!</h3>
              <a href='mailto:ciao@medible.uk'>ciao@medible.uk</a>
            </li>
            <li className="footer-item">
              <h3>oi!</h3>
              <a href='mailto:oi@medible.cl'>oi@medible.cl</a>
            </li>
          </ul>
        </div>
        <div className="footer-content">
					<a href="/">
						<img src={LogoFooter} alt="LOGO" />
					</a>
          <ul className="footer-nav-2">
            <li className="footer-item-2">
              ({new Date().getFullYear()}) {t("common:all_rights_reserved")}
            </li>
            <li className="footer-item-2">
              {t("common:privacy_and_data_policy")}
            </li>
            <li className="footer-item-2">
							<a 
								href="https://www.linkedin.com/company/medible/?originalSubdomain=cl" 
								target="_blank" 
								rel="noopener noreferrer">
								LinkedIn
							</a>
						</li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
