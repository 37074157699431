const our_expertise_it = [
  {
    name: "healthcare",
    description:
      "In Medible, applichiamo le nostre metodologie di design nel settore sanitario per migliorare l'innovazione, l'efficienza e l'efficacia, concentrandoci maggiormente sulle esigenze dei pazienti e dei fornitori.\nI nostri servizi di assistenza sanitaria possono essere utilizzati per determinare i percorsi dei pazienti, identificare i bisogni, facilitare l'adesione ai trattamenti dei pazienti, migliorare i servizi sanitari e supportare le sperimentazioni sui farmaci, tra le altre applicazioni.",
    link: {
      name: "PARLIAMO DEL TUO PROGETTO DI HEALTHCARE",
      href: "mailto:healthcare@medible.uk",
    },
  },
  {
    name: "finanza",
    description:
      "Medible ha una forte esperienza nel settore finanziario. Abbiamo lavorato sia con banche corporate tradizionali e compagnie assicurative, sia con fintech emergenti.\nNel nostro obiettivo di progettare il futuro del settore finanziario, aiutiamo le aziende a migliorare o creare nuovi prodotti, servizi, piattaforme digitali, piattaforme self-service e facilitare la collaborazione tra società finanziarie tradizionali e Fintech, tra molti altri servizi.",
    link: {
      name: "PARLIAMO DEI TUOI PROGETTI FINANZIARI",
      href: "mailto:finance@medible.uk",
    },
  },
  {
    name: "governo e ong",
    description:
      "Quando si tratta di lavorare con governi e ONG, la nostra vera sfida risiede nel promuovere l'accesso dei loro servizi alle persone, creando soluzioni che generino trazione e adesione con il loro pubblico mirato. Facilitare l'accesso ai servizi pubblici ci dà grande gioia, poiché vediamo un impatto diretto sulla felicità della società in generale.",
    link: {
      name: "PARLIAMO DI COME RENDERE UNA SOCIETÀ PIÙ FELICE",
      href: "mailto:government@medible.uk",
    },
  },
  {
    name: "telecom & utilities",
    description:
      "Al giorno d'oggi non è facile per le aziende di telecomunicazioni e utilities fare affari. Margini ristretti, aspettative di servizio elevate e sostenibilità sono solo alcune delle numerose sfide che gli attori di questi settori devono affrontare ogni giorno.\nIn Medible, aiutiamo le organizzazioni in questi settori a creare, o riprogettare, servizi e piattaforme che consentano loro di fornire un servizio migliore, più economico e più aderente al loro pubblico mirato. In questo modo, stiamo aiutando i nostri clienti a gestire un'operazione con livelli di servizio più elevati e più redditizia.",
    link: {
      name: "PARLIAMO DELLE TUE SFIDE TELECOM & UTILITIES",
      href: "mailto:telecomandutilities@medible.uk",
    },
  },
  {
    name: "servizi",
    description:
      "Le persone adorano le nuove società tecnologiche - Netflix, Airbnb, Amazon, Spotify, ecc. - e, di conseguenza, ora nutrono aspettative radicalmente diverse per altri fornitori di servizi.\nSe è così facile prenotare una stanza a Nagoia, come mai comprare un biglietto del treno per Manchester è così doloroso?\nEcco perché in Medible aiutiamo le società di servizi a creare, o rimodellare, servizi e piattaforme digitali per rendere le persone più felici e ottenere migliori risultati di business.",
    link: {
      name: "PARLIAMO DELLE SFIDE DEI TUOI SERVIZI",
      href: "mailto:services@medible.uk",
    },
  },
];

export default our_expertise_it;
