import common_en from "./en/common";
import common_es from "./es/common";
import common_it from "./it/common";
import common_pt from "./pt/common";

const resources = { 
  en: { common: common_en },
  es: { common: common_es },
  it: { common: common_it },
  pt: { common: common_pt },
};
export default resources;
