import our_expertise_es from "./expertise";

const common_es = {
  letstalk: "hablemos",
  english: "inglés",
  spanish: "español",
  italian: "italiano",
  portuguese: "portugués",
  we_are_a_global_crew: "somos un equipo global",
  of_swift_thinkers_and_makers: "de pensadores y creadores ágiles",
  designing_possible_futures: "diseñando futuros posibles",
  our_approach: "nuestro enfoque",
  our_approach_description:
    "Basándonos en nuestra experiencia y visión de futuro, innovamos hasta obtener respuestas y diseños que ofrezcan soluciones memorables para mejorar el rendimiento empresarial y las experiencias de las personas.",
  in_the_last_9_years: "en los últimos 9 años",
  design_leadership_to_more_than_300: "hemos liderado más de 300",
  projects_for_60_clients: "proyectos para 60 clientes, en más de 13 países.",
  our_expertise: {
    title: "nuestra experiencia",
    tab: {
      health_care: our_expertise_es[0].name,
      finance: our_expertise_es[1].name,
      government_ngo: our_expertise_es[2].name,
      telecom_utilities: our_expertise_es[3].name,
      services: our_expertise_es[4].name,
    },
    navigation: {
      health_care: "PROTECCIÓN DE SALUD",
      finance: our_expertise_es[1].name,
      government_ngo: our_expertise_es[2].name,
      telecom_utilities: our_expertise_es[3].name,
      services: our_expertise_es[4].name,
    },
    description: {
      health_care: our_expertise_es[0].description,
      finance: our_expertise_es[1].description,
      government_ngo: our_expertise_es[2].description,
      telecom_utilities: our_expertise_es[3].description,
      services: our_expertise_es[4].description,
    },
    link: {
      health_care: our_expertise_es[0].link.name,
      finance: our_expertise_es[1].link.name,
      government_ngo: our_expertise_es[2].link.name,
      telecom_utilities: our_expertise_es[3].link.name,
      services: our_expertise_es[4].link.name,
    },
  },
  our_stories: {
    title: "nuestras historias",
    items: {
      new_digital_government: "nuevo gobierno digital",
      in_person_banking_in_a_digital_world: "banca persona en un mundo digital",
      payment_experience: "experiencia de pago",
      a_more_inclusive_education: "una educación más inclusiva",
      digital_banking_for_all: "banca digital para todos",
      digital_journey_to_a_real_journey:
        "desde un viaje digital a un viaje real",
      telecom_globally_act_local: "telecomunicaciones globales, que actúen como locales",
      healthcare_with_more_care: "mejor asistencia en temas de salud",
    },
  },
  clients: "clientes",
  our_partners: "nuestros compañeros",
  get_in_touch: "contactanos",
  all_rights_reserved: "Todos los derechos reservados.",
  privacy_and_data_policy: "Política de privacidad y datos.",
};

export default common_es;
