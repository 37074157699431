import { useEffect } from 'react';
import Aos from 'aos';
import Landing from '../landing';
import Footer from 'src/layouts/Footer';
import HeaderLanding from '../layouts/Header/Header';

function Home() {
  useEffect(() => {
    Aos.init({
      // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
      offset: 150, // offset (in px) from the original trigger point
      delay: 30, // values from 0 to 3000, with step 50ms
      duration: 600, // values from 0 to 3000, with step 50ms
      easing: 'ease-in-out-quart', // default easing for AOS animations
      mirror: true, 
    });
  }, []);

  return (
    <div className="Home">
      <HeaderLanding />
      <Landing />
      <Footer />
    </div>
  );
}

export default Home;
