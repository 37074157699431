const our_expertise_es = [
  {
    name: "salud",
    description:
      "En Medible, aplicamos nuestra metodología de Diseño en la protección de la salud para mejorar la innovación, eficiencia, y eficacia, aumentando el foco en las necesidades del paciente y del proveedor.\nNuestros servicios de cuidado de la salud pueden ser utilizados para determinar los recorridos requeridos por los pacientes, identificar sus necesidades, aumentar su compromiso con su tratamiento, mejorar los servicios relacionados con el cuidado de la salud, apoyar ensayos clínicos, además de otras aplicaciones.",
    link: {
      name: "hablemos sobre tus proyectos de protección de salud",
      href: "mailto:healthcare@medible.uk",
    },
  },
  {
    name: "finanzas",
    description:
      "Medible cuenta con amplia experiencia en el sector financiero. Hemos trabajado, tanto con corporaciones bancarias tradicionales y compañías de seguros, como con Fintechs en crecimiento.\nDiseñamos el futuro de la industria financiera, ayudadando a compañías a mejorar -o crear- nuevos productos, servicios, plataformas digitales, plataformas de auto-servicio y facilitamos la colaboración entre empresas financieras tradicionales y las Fintechs, entre otros servicios que ofrecemos.",
    link: {
      name: "hablemos sobre tus proyectos financieros",
      href: "mailto:finance@medible.uk",
    },
  },
  {
    name: "gobierno y ong",
    description:
      "Cuando se trata de trabajar con gobiernos y ONG's, nuestro verdadero desafío consiste en promover el acceso de las personas a sus servicios, creando soluciones que generen lealtad y permanencia de su público objetivo.\nLa facilidad en el acceso a los servicios públicos nos llena de alegría, ya que podemos ver directamente el impacto en la felicidad de quienes componen nuestra sociedad a largo plazo.",
    link: {
      name: "hablemos sobre construir una sociedad feliz",
      href: "mailto:government@medible.uk",
    },
  },
  {
    name: "telecomunicaciones y  utilities",
    description:
      "Hoy en día no es fácil para las empresas de Telecomunicaciones y Utilities  hacer negocios. Márgenes de ganancias ajustados, altas expectativas de servicios y la sostenibilidad son algunos de los muchos desafíos que quienes participan en estos sectores enfrentan diariamente.\nEn Medible, ayudamos a las empresas en estos sectores a crear -o diseñar- servicios y plataformas que les permitan entregar un servicio mejor, más económico  servicio y con mayor lealtad en su público objetivo. De esa forma, estamos ayudando a nuestros clientes a operar un servicio con estándares más altos y rentables.",
    link: {
      name: "hablemos sobre tus retos en telecomunicaciones y utilities",
      href: "mailto:telecomandutilities@medible.uk",
    },
  },
  {
    name: "servicios",
    description:
      "Las personas simplemente aman las empresas de tecnología -Netflix, Airbnb, Spotify, etc.- y sus resultado, ahora crean expectativas completamente diferentes sobre los proveedores de servicios.\nSi es tan fácil reservar una habitación en Nagoya, ¿cómo es posible que comprar un boleto a Manchester resulte ser una experiencia tan compleja?\nEs por ello que en Medible ayudamos a las compañías de Servicios a crear darle nueva forma- servicios y plataformas digitales que hagan a las personas más felices, generando mejores resultados de negocios.",
    link: {
      name: "hablemos sobre tus desafíos de servicios",
      href: "mailto:services@medible.uk",
    },
  },
];

export default our_expertise_es;
