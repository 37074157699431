const our_expertise_en = [
  {
    name: "health care",
    description:
      "At Medible, we apply our Design methodology in healthcare to enhance innovation, efficiency, and efficacy, by increasing focus on patient and provider needs.\nOur health care services can be used to determine patient journeys, identify needs, increase adherence to treatments, enhance healthcare services, and support drug trials, among other applications.",
    link: {
      name: "let’s talk about your Health Care project",
      href: "mailto:healthcare@medible.uk",
    },
  },
  {
    name: "finance",
    description:
      "Medible has strong experience in the financial sector. We have worked both, with traditional corporate banks and insurance companies, as well as emerging Fintechs.\nIn our aim to design the future of the financial industry, we help companies enhance -or create- new products, services, digital platforms, self-service platforms and facilitate the collaboration between traditional financial companies and Fintechs, among many other services.",
    link: {
      name: "talk about your Financial Projects",
      href: "mailto:finance@medible.uk",
    },
  },
  {
    name: "government & ngo",
    description:
      "When it comes to working with governments and NGOs, our true challenge resides in promoting the access of their services to the people, creating solutions that generate traction and adherence with their targeted audience.\nEasing access to public services gives us great joy, as we see a direct impact on the happiness of society at large.",
    link: {
      name: "Let’s talk about how to make a happier societylet’s",
      href: "mailto:government@medible.uk",
    },
  },
  {
    name: "telecom & utilities",
    description:
      "Nowadays it’s not easy for Telecom and Utilities companies to do business. Tight margins, high service expectations, and sustainability are just some of the many challenges that actors in these sectors are facing every day.\nAt Medible, we help organizations in these industries create -or redesign- services and platforms that allow them to deliver a better, cheaper, and more adherent service to their targeted audience. In this way, we are helping our clients run an operation with higher service levels and more profitable.",
    link: {
      name: "let’s talk about your Telecom & Utilities challenges",
      href: "mailto:telecomandutilities@medible.uk",
    },
  },
  {
    name: "services",
    description:
      "People just love new tech companies -Netflix, Airbnb, Amazon,Spotify, etc- and as a result, they now hold radically different expectations for other service providers.\nIf it’s so easy to book a room in Nagoia, how come buying a train ticket to Manchester is such a pain? That’s why in Medible we help Service companies to create -or reshape- services and digital plataforms to make people happier and drive better business results.",
    link: {
      name: "let’s talk about your services challenges",
      href: "mailto:services@medible.uk",
    },
  },
];

export default our_expertise_en;
