import { FC,PropsWithChildren } from "react";
import { Carousel as CarouseDefault } from "react-responsive-carousel";
import { CarouselProps } from "react-responsive-carousel/lib/ts/components/Carousel/types";

export const Carousel: FC<PropsWithChildren<CarouselProps | any>> = ({children, ...rest}) => {
  return (
    <CarouseDefault
      emulateTouch
      showArrows={false}
      showStatus={false}
      showThumbs={false}
      showIndicators={false}
      {...rest}>{children}</CarouseDefault>
  );
};
