import our_expertise_en from "./expertise";

const common_en = {
  letstalk: "let's talk",
  english: "english",
  spanish: "spanish",
  italian: "italian",
  portuguese: "portuguese",
  we_are_a_global_crew: "we are a global crew",
  of_swift_thinkers_and_makers: "of swift thinkers and makers",
  designing_possible_futures: "designing possible futures",
  our_approach: "our approach",
  our_approach_description:
    "Based on our experience and vision of the future, we innovate until we obtain answers and design memorable solutions to enhance business performance and people experiences.",
  in_the_last_9_years: "in the last 9 years, we've brought",
  design_leadership_to_more_than_300: "design leadership to more than 300",
  projects_for_60_clients: "projects for 60 clients, in 13 countries.",
  our_expertise: {
    title: "our expertise",
    tab: {
      health_care: our_expertise_en[0].name,
      finance: our_expertise_en[1].name,
      government_ngo: our_expertise_en[2].name,
      telecom_utilities: our_expertise_en[3].name,
      services: our_expertise_en[4].name,
    },
    navigation: {
      health_care: our_expertise_en[0].name,
      finance: our_expertise_en[1].name,
      government_ngo: our_expertise_en[2].name,
      telecom_utilities: our_expertise_en[3].name,
      services: our_expertise_en[4].name,
    },
    description: {
      health_care: our_expertise_en[0].description,
      finance: our_expertise_en[1].description,
      government_ngo: our_expertise_en[2].description,
      telecom_utilities: our_expertise_en[3].description,
      services: our_expertise_en[4].description,
    },
    link: {
      health_care: our_expertise_en[0].link.name,
      finance: our_expertise_en[1].link.name,
      government_ngo: our_expertise_en[2].link.name,
      telecom_utilities: our_expertise_en[3].link.name,
      services: our_expertise_en[4].link.name,
    },
  },
  our_stories: {
    title: "our stories",
    items: {
      new_digital_government: "new digital government",
      in_person_banking_in_a_digital_world:
        "The bank's branch in a digital world",
      payment_experience: "payment experience",
      a_more_inclusive_education: "a more inclusive education",
      digital_banking_for_all: "digital banking for all",
      digital_journey_to_a_real_journey: "Digital journey to a real journey",
      telecom_globally_act_local: "telecom global, act local",
      healthcare_with_more_care: "healthcare with more care",
    },
  },
  clients: "clients",
  our_partners: "our partners",
  get_in_touch: "get in touch",
  all_rights_reserved: "All rights reserved.",
  privacy_and_data_policy: "Privacy and data policy.",
};

export default common_en;
