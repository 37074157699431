import { useTranslation } from 'react-i18next';
import LandingIlus1 from '../assets/img/landing.ilustration1.webp';
import LandingIlus3 from '../assets/img/landing.ilustration3.webp';
import GIF1 from '../assets/gif/gif-1.gif';

const LandingHero = () => {
  const { t } = useTranslation();

  return (
    <div className="landing container">
      <div className="landing-hero">
        <div className="landing-hero-into">
          <div className="landing-hero-text">
            <h2 data-aos="fade-up">
              {t("common:we_are_a_global_crew")}
            </h2>
            <h2 data-aos="fade-up" data-aos-duration={800}>
              {t("common:of_swift_thinkers_and_makers")}
            </h2>
            <h2 data-aos="fade-up" data-aos-duration={1000}>
              {t("common:designing_possible_futures")}
            </h2>
          </div>

          <img
            data-aos="fade-up-left"
            data-aos-duration={1000}
            data-aos-delay="100"
            data-aos-offset={200}
            className="landing-hero-img"
            loading="lazy" src={GIF1} alt="gif-1" />
        </div>

        <div className="landing-hero-approach">
          <h2
            data-aos="fade-up"
          
            className="landing-hero-approach title">
              {t("common:our_approach")}
            </h2>
          <div className="landing-hero-approach__content">
            <p
              data-aos="fade-up"
              data-aos-duration={800}>
                {t("common:our_approach_description")}
            </p>
            <img
              data-aos="fade-left"
              data-aos-duration={1000}
              data-aos-offset={200}
              className="landing-hero-approach__img"
              src={LandingIlus1}
              alt="error" />
          </div>
        </div>

        <div
          className="landing-hero-into into-small">
          <div
            className="landing-hero-text">
            <h2 data-aos="fade-up">
              {t("common:in_the_last_9_years")}
            </h2>
            <h2
              data-aos="fade-up"
              data-aos-duration={700}
              data-aos-offset={200}>
                {t("common:design_leadership_to_more_than_300")}
              </h2>
            <h2
              data-aos="fade-up"
              data-aos-duration={800}
              data-aos-offset={200}>
                {t("common:projects_for_60_clients")}
              </h2>
          </div>
          <img
            data-aos="fade-right"
            data-aos-duration={1000}
            data-aos-delay={100}
            data-aos-offset={200}

            className="landing-hero-img"
            src={LandingIlus3}
            alt="error"
          />
        </div>
      </div>
    </div>
  )
};

export default LandingHero;