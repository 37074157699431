const our_expertise_pt = [
  {
    name: "ASSISTÊNCIA MÉDICA",
    description:
      "Em Medible, aplicamos nossas metodologias de design na área da saúde para aprimorar a inovação, a eficiência e a eficácia, aumentando o foco nas necessidades do paciente e do profissional.\nNossos serviços de assistência médica podem ser usados ​​para determinar as jornadas dos pacientes, identificar necessidades, facilitar a adesão aos tratamentos dos pacientes, aprimorar os serviços de saúde e apoiar testes de medicamentos, entre outras aplicações.",
    link: {
      name: "VAMOS FALAR SOBRE SEU PROJETO DE SAÚDE",
      href: "mailto:healthcare@medible.uk",
    },
  },
  {
    name: "FINANÇA",
    description:
      "Medible tem uma forte experiência no setor financeiro. Trabalhamos tanto com bancos corporativos tradicionais e seguradoras quanto com fintechs emergentes.\nCom o objetivo de desenhar o futuro da indústria financeira, ajudamos as empresas a aprimorar - ou criar - novos produtos, serviços, plataformas digitais, plataformas de autoatendimento e facilitar a colaboração entre empresas financeiras tradicionais e Fintechs, entre muitos outros serviços.",
    link: {
      name: "FALEMOS SOBRE SEUS PROJETOS FINANCEIROS",
      href: "mailto:finance@medible.uk",
    },
  },
  {
    name: "governo e ong",
    description:
      "Quando se trata de trabalhar com governos e ONGs, nosso verdadeiro desafio está em promover o acesso de seus serviços às pessoas, criando soluções que gerem tração e adesão com seu público-alvo. Facilitar o acesso aos serviços públicos nos dá muita alegria, pois vemos um impacto direto na felicidade da sociedade como um todo.",
    link: {
      name: "VAMOS FALAR SOBRE COMO FAZER UMA SOCIEDADE MAIS FELIZ",
      href: "mailto:government@medible.uk",
    },
  },
  {
    name: "telecom & utilities",
    description:
      "Hoje em dia não é fácil para as empresas de Telecom e Utilities fazer negócios. Margens apertadas, altas expectativas de serviço e sustentabilidade são apenas alguns dos muitos desafios que os atores desses setores enfrentam todos os dias.\nEm Medible ajudamos as organizações destes setores a criar -ou redesenhar- serviços e plataformas que lhes permitam oferecer um serviço melhor, mais barato e mais aderente ao seu público-alvo. Desta forma, estamos ajudando nossos clientes a realizar uma operação com níveis de serviço mais elevados e mais rentáveis.",
    link: {
      name: "VAMOS FALAR SOBRE SEUS DESAFIOS DE TELECOM & UTILITIES",
      href: "mailto:telecomandutilities@medible.uk",
    },
  },
  {
    name: "SERVIÇOS",
    description:
      "As pessoas simplesmente adoram novas empresas de tecnologia -Netflix, Airbnb, Amazon, Spotify, etc- e, como resultado, agora têm expectativas radicalmente diferentes para outros provedores de serviços.\nSe é tão fácil reservar um quarto em Nagoia, por que comprar uma passagem de trem para Manchester é tão chato?\nÉ por isso que em Medible ajudamos as empresas de serviços a criar - ou reformular - serviços e plataformas digitais para tornar as pessoas mais felizes e gerar melhores resultados nos negócios.",
    link: {
      name: "VAMOS FALAR SOBRE SEUS DESAFIOS DE SERVIÇOS",
      href: "mailto:services@medible.uk",
    },
  },
];

export default our_expertise_pt;
