import { Link } from 'react-router-dom';
import { useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import { useTranslation } from 'react-i18next';

import './Header.scss';
import i18n from 'src/utils/locales/i18';

import Logo from '../../assets/img/Logo.svg';
import Local from '../../assets/img/local.svg';

const HeaderLanding = () => {
  const { t } = useTranslation();
  const [isMenu, setIsMenu] = useState<boolean>(false);
  const [isMenu2, setIsMenu2] = useState<boolean>(false);

  const exactLink = (locale: string) => {
    if (i18n.language === locale) {
      return 'active'
    }
    return ''
  }

  const handleCloseTap = () => setTimeout(() => setIsMenu(false), 100);
  return (
    <header className="l-header">
      <a href="/">
        <img src={Logo} alt="logo" />
      </a>
      <ul className={`l-header__nav${isMenu ? ' active' : ''}`}>
        <li
          className={`l-header__item ${exactLink("en")}`}>
          <Link to="/en" onClick={handleCloseTap}>{t("common:english")}</Link>
        </li>
        <li
          className={`l-header__item ${exactLink("es")}`}>
          <Link to="/es" onClick={handleCloseTap}>{t("common:spanish")}</Link>
        </li>
        <li
          className={`l-header__item ${exactLink("pt")}`}>
          <Link to="/pt" onClick={handleCloseTap}>{t("common:portuguese")}</Link>
        </li>
        <li
          className={`l-header__item ${exactLink("it")}`}>
          <Link to="/it" onClick={handleCloseTap}>{t("common:italian")}</Link>
        </li>
      </ul>
      <div className="l-header-btn-responsive">
        <button
          className={`l-menu-btn${isMenu ? ' open' : ''}`}
          onClick={() => setIsMenu(current => {
            setIsMenu2(false);
            return !current;
          })}>
          <img src={Local} alt="local" />
        </button>
        <button
          className={`l-menu-btn${isMenu2 ? ' open' : ''}`}
          onClick={() => setIsMenu2(current => {
            setIsMenu(false);
            return !current;
          })}>
          <div className="l-menu-btn__burger" />
        </button>
      </div>
      <ul className={`l-header__nav-2${isMenu2 ? ' active' : ''}`}>
        <li className="l-header__item external">
          <HashLink
            to="#clients"
            onClick={() => setIsMenu2(false)}>{t("common:clients")}</HashLink>
        </li>
        <li className="l-header__item external">
          <HashLink
            to="#our-stories"
            onClick={() => setIsMenu2(false)}>
            {t("common:our_stories:title")}
            </HashLink>
        </li>
        <li className="l-header__item external">
          <a
            rel="noopener noreferrer"
            href="https://stories.medible.cl/blog/"
            onClick={() => setIsMenu2(false)}>blog</a>
        </li>
        <li className="l-header__item external">
          <HashLink
            to="#footer"
            onClick={() => setIsMenu2(false)}>{t("common:letstalk")}</HashLink>
        </li>
      </ul>
    </header>
  )
}

export default HeaderLanding;
