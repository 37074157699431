import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AiOutlineArrowRight } from "react-icons/ai";
import LandingHero from "./Hero";
import "./Landing.scss";

import expertiserJSON from "./expertise.json";
import { Carousel } from "../components";
import StoriesItem from "./components/StoriesItem";
import STORIESLIST, { DIAGRAM } from 'src/constants/landing';
import i18n from 'src/utils/locales/i18';
import { Box } from 'native-piece';

const Landing = () => {
  const { t } = useTranslation();
  const lang = i18n.language;

  const [currentSlider, setCurrentSlider] = useState(0);

  return (
    <>
      <LandingHero />
      <Box
        data-aos="fade-up"
        data-aos-duration={600}
        backgroundImage={`url(${DIAGRAM[lang]})`}
        className="constainer landing-fullyng-img" />
      <div className="landing-expertise">
        <div
          data-aos="fade-up-right"
          data-aos-duration={600}>
          <h2 className="expertise__title" data-lang={lang}>
            {t("common:our_expertise:title")}
          </h2>
          <ul className="expertise__nav">
            {expertiserJSON.map(({ name }, index) => (
              <li
                key={name}
                onClick={() => setCurrentSlider(index)}
                className={`expertise__item${currentSlider === index ? ' active' : ''}`}>
                {t(`common:our_expertise:tab:${name}`)}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <Carousel
            className="carousel-expertise"
            selectedItem={currentSlider}
            onChange={(item: number) => setCurrentSlider(item)}>
            {expertiserJSON.map(({ name, link }) => (
              <div key={name} className="carousel-card">
                <div className="carousel-content">
                  <h4>{t(`common:our_expertise:navigation:${name}`)}</h4>
                  <p>{t(`common:our_expertise:description:${name}`)}</p>
                </div>
                <a href={link.href} className="carousel-link">
                  <p>
                    {t(`common:our_expertise:link:${name}`)}
                  </p>
                  <AiOutlineArrowRight />
                </a>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
      <div id="our-stories" className="landing-stories container">
        <h2
          data-aos="fade-down-right"
          data-aos-duration={600}
          data-lang={lang}
          className="landing-hero-stories_title title">{t("common:our_stories:title")}</h2>
        <ul className="landing-hero-stories__content">
          {STORIESLIST.map((data, index) => (
            <StoriesItem
              key={index}
              src={data.img}
              url={data.url[lang]}
              alt={t(`common:our_stories:items:${data.name}`)}
            />
          ))}
        </ul>
      </div>
      <div id="clients" className="landing-clients">
        <h2
          data-aos="fade-down-right"
          data-aos-duration={600}
          className="landing-hero-clients_title title">{t("common:clients")}</h2>
        <ul className="landing-hero-clients__content">
          <li
            data-aos="fade-down-right"
            data-aos-duration={600}
            className="landing-hero-clients__item client-1"></li>
          <li
            data-aos="fade-down-right"
            data-aos-duration={600}
            className="landing-hero-clients__item client-2"></li>
          <li
            data-aos="zoom-in"
            data-aos-duration={600}
            className="landing-hero-clients__item only">{t("common:our_partners")}</li>
          <li
            data-aos="fade-up"
            data-aos-duration={600}
            className="landing-hero-clients__item client-3"></li>
          <li
            data-aos="fade-up"
            data-aos-duration={600}
            className="landing-hero-clients__item client-4"></li>
        </ul>
      </div>
    </>
  )
}

export default Landing;
