import Efect1 from '../assets/img/stories/efecto-1.webp';
import Efect2 from '../assets/img/stories/efecto-2.webp';
import Efect3 from '../assets/img/stories/efecto-3.webp';
import Efect4 from '../assets/img/stories/efecto-4.webp';
import Efect5 from '../assets/img/stories/efecto-5.webp';
import Efect6 from '../assets/img/stories/efecto-6.webp';
import Efect8 from '../assets/img/stories/efecto-8.webp';
import DiagramEn from '../assets/img/details/diagram-en.webp';
import DiagramEs from '../assets/img/details/diagram-es.webp';
import DiagramIt from '../assets/img/details/diagram-it.webp';
import DiagramPt from '../assets/img/details/diagram-pt.webp';

export const DIAGRAM: any = {
  en: DiagramEn,
  es: DiagramEs,
  it: DiagramIt,
  pt: DiagramPt,
}

type Linktype = { [key: string]: string };

export const LANG = ['es', 'en', 'it', 'pt'];

const new_digital_government_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/nuevo-gobierno-digital/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/new-digital-government/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/nuovo-governo-digitale/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/novo-governo-digital/`,
} as const;

const in_person_banking_in_a_digital_world_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/banca-persona-en-un-mundo-digital/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/the-banks-branch-in-a-digital-world/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/la-filiale-della-banca-in-un-mondo-digitale/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/agencia-bancaria-no-mundo-digital/`,
} as const;

const payment_experience_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/experiencia-de-pago/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/payment-experience/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/esperienza-di-pagamento/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/experiencia-de-pagamento/`,
} as const;

const a_more_inclusive_education_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/una-educacion-mas-inclusiva/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/a-more-inclusive-education/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/uneducazione-piu-inclusivaa/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/uma-educacao-mais-inclusiva/`,
} as const;

const digital_journey_to_a_real_journey_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/desde-un-viaje-digital-a-un-viaje-real/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/digital-journey-to-a-real-journey/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/viaggio-digitale-verso-un-viaggio-reale/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/viagem-digital-para-uma-viagem-real/`,
} as const;

const healthcare_with_more_care_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/mejor-asistencia-en-temas-de-salud/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/healthcare-with-more-care/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/sanita-con-piu-cure/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/saude-com-mais-cuidado/`,
} as const;

const digital_banking_for_all_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/banca-digital-para-todos/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/digital-banking-for-all/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/banca-digitale-per-tutti/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/banca-digital-para-todos-pt/`,
} as const;

const telecom_globally_act_local_links: Linktype = {
  es: `${process.env.REACT_APP_CLIENT_URL}/telecomunicaciones-globales-que-actuen-como-locales/`,
  en: `${process.env.REACT_APP_CLIENT_URL}/en/telecom-global-act-local/`,
  it: `${process.env.REACT_APP_CLIENT_URL}/it/telecom-globale-agire-locale/`,
  pt: `${process.env.REACT_APP_CLIENT_URL}/pt/telecom-global-ato-local/`,
} as const;

const STORIESLIST = [
  {
    name: "new_digital_government",
    url: new_digital_government_links,
    img: Efect1,
  },
  {
    name: "in_person_banking_in_a_digital_world",
    url: in_person_banking_in_a_digital_world_links,
    img: Efect2,
  },
  {
    name: "payment_experience",
    url: payment_experience_links,
    img: Efect3,
  },
  {
    name: "a_more_inclusive_education",
    url: a_more_inclusive_education_links,
    img: Efect4,
  },
  {
    name: "digital_banking_for_all",
    url: digital_banking_for_all_links,
    img: Efect5,
  },
  {
    name: "digital_journey_to_a_real_journey",
    url: digital_journey_to_a_real_journey_links,
    img: Efect6,
  },
  {
    name: "telecom_globally_act_local",
    url: telecom_globally_act_local_links,
    img: Efect2,
  },
  {
    name: "healthcare_with_more_care",
    url: healthcare_with_more_care_links,
    img: Efect8,
  },
] as const;

export default STORIESLIST;
