import our_expertise_pt from "./expertise";

const common_pt = {
  letstalk: "VAMOS CONVERSAR",
  english: "INGLÊS",
  spanish: "ESPANHOL",
  italian: "ITALIANO",
  portuguese: "PORTUGUÊS",
  we_are_a_global_crew: "NÓS SOMOS UM TIME GLOBAL",
  of_swift_thinkers_and_makers: "DE PENSADORES RÁPIDOS E",
  designing_possible_futures: "CREAMOS FUTUROS POSSÍVEIS",
  our_approach: "NOSSA ABORDAGEM",
  our_approach_description:
    "Com base na nossa experiência e visão de futuro, inovamos até obter respostas e desenhamos soluções memoráveis ​​para potenciar o desempenho dos negócios e as experiências das pessoas.",
  in_the_last_9_years: "NOS ÚLTIMOS 9 ANOS, LIDERAMOS",
  design_leadership_to_more_than_300: "MAIS DE 300 PROJETOS",
  projects_for_60_clients: "PARA 60 CLIENTES, EM 13 PAÍSES.",
  our_expertise: {
    title: "NOSSA EXPERIÊNCIA",
    tab: {
      health_care: "ASSISTÊNCIA MÉDICA",
      finance: our_expertise_pt[1].name,
      government_ngo: our_expertise_pt[2].name,
      telecom_utilities: our_expertise_pt[3].name,
      services: our_expertise_pt[4].name,
    },
    navigation: {
      health_care: our_expertise_pt[0].name,
      finance: our_expertise_pt[1].name,
      government_ngo: our_expertise_pt[2].name,
      telecom_utilities: our_expertise_pt[3].name,
      services: our_expertise_pt[4].name,
    },
    description: {
      health_care: our_expertise_pt[0].description,
      finance: our_expertise_pt[1].description,
      government_ngo: our_expertise_pt[2].description,
      telecom_utilities: our_expertise_pt[3].description,
      services: our_expertise_pt[4].description,
    },
    link: {
      health_care: our_expertise_pt[0].link.name,
      finance: our_expertise_pt[1].link.name,
      government_ngo: our_expertise_pt[2].link.name,
      telecom_utilities: our_expertise_pt[3].link.name,
      services: our_expertise_pt[4].link.name,
    },
  },
  our_stories: {
    title: "NOSSAS HISTÓRIAS",
    items: {
      new_digital_government: "NOVO GOVERNO DIGITAL",
      in_person_banking_in_a_digital_world: "AGÊNCIA BANCARIA NO MUNDO DIGITAL",
      payment_experience: "EXPERIÊNCIA DE PAGAMENTO",
      a_more_inclusive_education: "UMA EDUCAÇÃO MAIS INCLUSIVA",
      digital_banking_for_all: "BANCA DIGITAL PARA TODOS",
      digital_journey_to_a_real_journey: "VIAGEM DIGITAL PARA UMA VIAGEM REAL",
      telecom_globally_act_local: "TELECOM GLOBAL, ATO LOCAL",
      healthcare_with_more_care: "SAÚDE COM MAIS CUIDADO",
    },
  },
  clients: "clientes",
  our_partners: "our partners",
  get_in_touch: "CONTATE-NOS",
  all_rights_reserved: "Todos os direitos reservados",
  privacy_and_data_policy: "Política de privacidade e dados.",
};

export default common_pt;
