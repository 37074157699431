import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useEffect } from "react";
import Home from "./views/Home";
import i18n from "./utils/locales/i18";
import { LANG } from "./constants/landing";

export const Router = () => {
  const location = useLocation();

  useEffect(() => {
    const lang = location.pathname.substring(1);
    if (LANG.includes(lang)) {
      i18n.changeLanguage(lang);
    }
  }, [location]);

  return (
    <Switch>
      <Route exact path={`/:locale(${LANG.join("|")})`} component={Home} />
      <Redirect to={"/"+process.env.REACT_APP_LANGUAGE} />
    </Switch>
  )
}