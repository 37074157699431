import { DefaultTFuncReturn } from 'i18next';
import { forwardRef } from 'react';
import i18n from 'src/utils/locales/i18';

interface IStoriesItem {
  src?: string;
  url?: string;
  alt?: string | DefaultTFuncReturn;
}

const StoriesItem =
  forwardRef<HTMLLIElement, IStoriesItem>(({ src, alt, url }) => {
    const lang = i18n.language;
    return (
      <li
        data-aos="fade-up"
        data-aos-duration={600}
        className="stories-item">
        <figure className="stories-figure">
          <img src={src} alt={alt as string} />
          <figcaption data-lang={lang}>
            <a href={url} target="_parent" rel="noopener noreferrer">
              <p>
                {alt}
              </p>
            </a>
          </figcaption>
        </figure>
      </li>
    );
  });

export default StoriesItem;
